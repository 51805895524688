import clsx from "clsx";
import Button from "components/generics/Button";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    button: {
        color: "#2B2B2B!important",
        padding: "5px 180px!important",
        fontSize: "26px!important",
    },
    smallButton: {
        padding: "3px 50px!important",
    },
    largeButton: {},
    primaryColor: {
        color: "#000!important",
    },
    secondaryColor: {
        color: "#fff!important",
    },
    '@media screen and (max-width:760px)': {
        button: {
            color: "#2B2B2B",
            padding: "3px 80px!important",
            fontSize: "22px!important"
        },
        smallButton: {
            padding: "3px 50px!important",
        },
    }
})

function EnrolButton({
    className = "",
    children,
    external = false,
    link = null,
    loading = false,
    ...props
}) {
    const classes = useStyles();

    const button = () => {
        return (
            <Button
                variant="contained"
                className={clsx(
                    classes.button,
                    className,
                    props.color === "primary" ? classes.primaryColor : classes.secondaryColor,
                    props.size === "large" ? classes.largeButton : classes.smallButton,
                )}
                {...props}
                disabled={loading}
            >
                {children}
            </Button>
        )
    }

    return (
        link
            ? <a
                href={link}
                className={clsx("text-decoration-none", loading ? "disabled" : "")}
                target={external ? '_blank' : ''}
                rel="noopener"
            >
                {button()}
            </a>
            : button()

    )
}
export default EnrolButton;

