import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import EnrolNowCTA from 'components/landing/gpay/EnrolNowCTA';
import { createUseStyles } from 'react-jss';
import ReactMarkdown from 'react-markdown';
import {
  DOWNLOAD_CURRICULUM_EVENT,
  handleLinkClick,
  YOUTUBE_BUTTON_EVENT
} from 'utils/events';
import YouTubeRed from 'assets/web/YouTubeRed.svg';
import Link from 'next/link';
import config from 'config';

const useStyles = createUseStyles({
  container: {
    position: 'relative'
  },
  section: {},
  uppercut: {
    top: '-1px',
    overflow: 'hidden',
    position: 'absolute',
    left: 0,
    width: '100%',
    lineHeight: 0,
    direction: 'ltr',
    '& svg': {
      height: '30px',
      transform: 'translateX(-50%) rotateY(180deg)',
      display: 'block',
      width: 'calc(100% + 1.3px)',
      position: 'relative',
      left: '50%',
      color: '#fff'
    }
  },
  wrapper: {
    padding: '100px 0',
    margin: '0 auto'
  },
  dropRight: {
    backgroundImage:
      'url(https://dmaa7docgq9sl.cloudfront.net/assets/2021/7/7/AA7aHuLz/frame-1.png)',
    height: '202px',
    width: '213px',
    position: 'absolute',
    right: 0,
    top: 0,
    opacity: 0.8
  },
  dropLeft: {
    backgroundImage:
      'url(https://dmaa7docgq9sl.cloudfront.net/assets/2021/7/7/AA7aHuLz/frame-1.png)',
    height: '280px',
    width: '250px',
    top: 0,
    left: 0,
    opacity: 0.8,
    position: 'absolute'
  },
  title: {
    color: '#fff',
    marginBottom: '20px',
    lineHeight: '220px'
  },
  description: {
    '& p': {
      color: '#DBEEFF',
      wordBreak: 'break-all'
    }
  },
  button: {
    fontSize: '16px!important',
    borderRadius: '100px!important',
    padding: '5px 50px!important'
  },
  footer: {
    display: 'flex',
    // flexDirection: "column"
    alignItems: 'flex-start',
    justifyContent: 'space-around'
  },
  youtubeSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      // width: ""
      width: '50%',
      margin: '20px 0'
    }
  },
  youtubeTitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      margin: '0 6px'
    }
  },
  footerSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px'
  },
  // linksSection: {
  //     display: "flex",

  // },
  footerHeader: {
    color: '#fff',
    fontWeight: '600',
    fontSize: '16px!important',
    marginBottom: '15px!important'
  },
  footerTitle: {
    color: '#fff',
    fontWeight: '100!important',
    width: '80%',
    margin: '3px auto!important',
    '& a': {
      textDecoration: 'none',
      color: 'inherit'
    }
  },
  '@media screen and (max-width: 760px)': {
    wrapper: {
      width: 'auto',
      paddingRight: '20px',
      paddingLeft: '20px'
    },
    dropRight: {
      display: 'none'
    },
    dropLeft: {
      display: 'none'
    },
    footer: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
    youtubeSection: {},
    youtubeTitle: {},
    footerSection: {},
    footerHeader: {},
    footerTitle: {}
  }
});

function BlueFooter({
  data,
  pageType,
  common,
  ctaLinks,
  showDescription = false,
  footer = true,
  handleEnrolNowClick,
  loading = false
}: {
  data?: any;
  pageType?: string;
  common?: any;
  ctaLinks?: any;
  showDescription?: boolean;
  footer?: boolean;
  handleEnrolNowClick?: any;
  loading?: boolean;
}) {
  const classes = useStyles();

  // const renderCTAButton = () => {
  //     if (pageType === "cred") {
  //         return (
  //             <a
  //                 className="text-decoration-none"
  //                 // href={ctaUrl || ""}
  //                 id="make-happy-box"
  //                 onClick={handleEnrolNowClick}
  //             >
  //                 <Button
  //                     variant="contained"
  //                     color="primary"
  //                     className={clsx(classes.button, "sbutton")}
  //                 >
  //                     {ctaText || "Start Free Trial"}
  //                 </Button>
  //             </a>
  //         )
  //     } else (
  //         <a
  //             className="text-decoration-none"
  //             href={ctaUrl || ""}
  //             id="make-happy-box"
  //             onClick={(e) => handleLinkClick(ctaType, e)}
  //         >
  //             <Button
  //                 variant="contained"
  //                 color="primary"
  //                 className={clsx(classes.button, "sbutton")}
  //             >
  //                 {ctaText || "Start Free Trial"}
  //             </Button>
  //         </a>
  //     )
  // }

  const footerData = [
    // Commented as we've removed the streams section for now
    // {
    //   title: 'Streams',
    //   data: [
    //     {
    //       key: 'coding',
    //       title: 'Computational Thinking',
    //       link: '/coding/'
    //     },
    //     {
    //       key: 'science',
    //       title: 'Scientific Thinking',
    //       link: '/science/'
    //     }
    //   ]
    // },

    // {
    //     title: "Download Curriculum",
    //     data: [
    //         {
    //             key: "science-year-1",
    //             title: "Science Year 1",
    //             link: config.SCIENCE_CURRICULUM,
    //         },
    //         {
    //             key: "computer-science-fundamentals",
    //             title: "Computer Science Fundamentals",
    //             link: config.CODING_CURRICULUM,
    //             mixPanelEvent: 'curriculum-coding-footer'
    //         },
    //     ],
    // },
    {
      title: 'StayQrious',
      data: [
        {
          key: 'teachers',
          title: 'Teachers',
          link: '/teachers/'
        },
        // {
        //     key: "our-story",
        //     title: "Our Story",
        //     link: "",
        // },
        {
          key: 'press-&-media',
          title: 'Press & Media',
          link: '/press/'
        },
        // {
        //     key: "careers",
        //     title: "Careers",
        //     link: "",
        // },
        {
          key: 'terms-&-conditions',
          title: 'Terms & Conditions',
          link: '/terms-and-conditions/'
        },
        {
          key: 'privacy-policy',
          title: 'Privacy Policy',
          link: '/privacy-policy/'
        }
      ]
    }
  ];

  return (
    <>
      <div className={classes.container}>
        <div
          className={classes.section}
          style={{
            backgroundColor: '#0A3F6E'
          }}
        >
          <div className={classes.dropLeft}></div>
          <div className={classes.dropRight}></div>
          <div className="max-width relative">
            <div className={classes.wrapper}>
              <div className="">
                <div className="center">
                  <Typography
                    variant="h1"
                    className={clsx(classes.title, 'mtb10')}
                  >
                    Help your child learn the right way, at StayQrious
                  </Typography>
                  {ctaLinks ? (
                    <EnrolNowCTA
                      id="make-happy-box"
                      type={pageType}
                      common={common}
                      ctaLinks={ctaLinks}
                      handleEnrolNowClick={handleEnrolNowClick}
                      refundMessage={false}
                      size="small"
                      color="primary"
                      loading={loading}
                    />
                  ) : (
                    ''
                  )}
                </div>
                {showDescription && data ? (
                  <span className={classes.description}>
                    <ReactMarkdown source={data.description} />
                  </span>
                ) : (
                  ''
                )}
                {footer ? (
                  <div className={classes.footer}>
                    <div className={classes.youtubeSection}>
                      <a
                        id="youtube-footer-image"
                        href={config.YOUTUBE_LINK}
                        className="text-decoration-none center"
                        onClick={(e) =>
                          handleLinkClick(
                            YOUTUBE_BUTTON_EVENT,
                            e,
                            config.YOUTUBE_LINK
                          )
                        }
                      >
                        <img
                          src={
                            'https://img.stayqrious.com/website/2021/7/15/hTAXvdBT/unnamed-1.png'
                          }
                          alt="StayQrious"
                        />
                      </a>
                      <a
                        id="youtube-footer-link"
                        href={config.YOUTUBE_LINK}
                        className="text-decoration-none"
                        onClick={(e) =>
                          handleLinkClick(
                            YOUTUBE_BUTTON_EVENT,
                            e,
                            config.YOUTUBE_LINK
                          )
                        }
                      >
                        <Typography
                          variant="h5"
                          className={clsx('white', classes.youtubeTitle)}
                        >
                          Subscribe to us on <YouTubeRed /> YouTube
                        </Typography>
                      </a>
                    </div>
                    {/* <div className={classes.linksSection}> */}
                    {footerData.map((item, index) => (
                      <div className={classes.footerSection} key={index}>
                        <Typography
                          variant="h6"
                          className={classes.footerHeader}
                        >
                          {item.title}
                        </Typography>
                        {item.data.map((footerItem, index) => (
                          <Typography
                            variant="body2"
                            key={index}
                            className={clsx(classes.footerTitle, 'pointer')}
                          >
                            {
                              // Commented as we had to remove the Download Curriculum Section
                              // footerItem.mixPanelEvent
                              //     ?
                              //     <a
                              //         id={footerItem.mixPanelEvent}
                              //         href={footerItem.link}
                              //         onClick={(e) => handleLinkClick(DOWNLOAD_CURRICULUM_EVENT, e, footerItem.link)}
                              //     >
                              //         {footerItem.title}
                              //     </a>
                              //     :
                              <Link href={footerItem.link}>
                                {footerItem.title}
                              </Link>
                            }
                          </Typography>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : (
                  // </div>
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlueFooter;
