import { createUseStyles } from "react-jss";
import Wedge from 'assets/web/wedge.svg';
import clsx from "clsx";
import { NONE } from "apisauce";

const useStyles = createUseStyles({
    container: {
        position: 'relative',
    },
    section: {
    },
    uppercut: {
        top: '-1px',
        overflow: 'hidden',
        position: 'absolute',
        left: 0,
        width: '100%',
        lineHeight: 0,
        direction: 'ltr',
        "& svg": {
            height: '30px',
            transform: 'translateX(-50%) rotateY(180deg)',
            display: 'block',
            width: 'calc(100% + 1.3px)',
            position: 'relative',
            left: '50%',
            color: '#fff',
        }
    },
    wrapper: {
        padding: "20px 0",
    },
    slantSM: {},
    '@media screen and (max-width: 760px)': {
        container: {

        },
        wrapper: {
            padding: "10px 0",
        },
        section: {
            padding: '0 10px',
        },
        slantSM: {
            display: "none",
        }
    }
})

function BlockBox({
    id = "",
    backgroundColor = '#F7F8FA',
    slantSM = true,
    slant = true,
    children
}) {
    const classes = useStyles();
    return (
        <>
            <div
                className={classes.container}
                id={id}
            >
                <div
                    className={classes.section}
                    style={{
                        backgroundColor: backgroundColor
                    }}
                >
                    {
                        slant
                            ?
                            <div className={clsx(classes.uppercut, slantSM ? classes.slantSM : "")}>
                                <Wedge />
                            </div>
                            : ''
                    }
                    <div className="max-width">
                        <div className={classes.wrapper}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlockBox;