import { ENROL_EVENT, handleLinkClick } from 'utils/events';
import EnrolButton from '../EnrolButton';
import { Typography } from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles({
  enrolButton: {
    // color: "#fff!important",
    // padding: "3px 50px!important",
    fontSize: '24px!important'
  },
  '@media screen and (max-width:760px)': {
    enrolButton: {
      fontSize: '20px!important'
    }
  }
});

function EnrolNowCTA({
  id,
  type,
  common,
  ctaLinks,
  handleEnrolNowClick = null,
  size = 'large',
  color = 'secondary',
  refundMessage = true,
  loading = false
}) {
  const classes = useStyles();

  const renderEnrolNow = () => {
    if (
      type == 'cred' ||
      type == 'gpay' ||
      type == 'scholarship' ||
      type == 'merit-admission' ||
      type == 'core scholarship'
    ) {
      return (
        <EnrolButton
          id={id}
          color={color}
          className={clsx(classes.enrolButton)}
          onClick={handleEnrolNowClick}
          size={size}
          loading={loading}
        >
          {common.cta_button_enroll_text}
        </EnrolButton>
      );
    } else {
      return (
        <a
          className={clsx('text-decoration-none', loading ? 'disabled' : '')}
          href={ctaLinks.cta_enroll_now}
          id={id}
          onClick={(e) =>
            handleLinkClick(ENROL_EVENT, e, ctaLinks.cta_enroll_now)
          }
        >
          <EnrolButton
            color={color}
            className={clsx(classes.enrolButton)}
            size={size}
            loading={loading}
          >
            {common.cta_button_enroll_text}
          </EnrolButton>
        </a>
      );
    }
  };

  return (
    <>
      {renderEnrolNow()}
      {refundMessage ? (
        <Typography
          variant="body2"
          color="primary"
          className="center bold mtb20"
        >
          {common.refund_message}
        </Typography>
      ) : (
        ''
      )}
    </>
  );
}

export default EnrolNowCTA;
